<template>
  <ClientsList/>
</template>

<script>
export default {
  name: 'clients',
  components: {
    ClientsList: () => import('@/components/admin/clients/ClientsList')
  }
}
</script>